import React from 'react'


export const Footer = () => {
    return (
        <section id='Footer' className='w-full h-10 text-white'>

            <div className=' w-full h-10 flex flex-row justify-between px-12'>
                <div>
                    Copyright &copy; 2024. All rights are reserved
                </div>
            </div>

        </section>
    )
}
