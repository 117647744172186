import React from 'react'
import { useState, useEffect } from 'react'

export const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth > 1024) {
        setIsOpen(false); 
      }
    };
    if (isOpen) {
      window.addEventListener('resize', checkScreenSize);
    }

    // Initially check if desktop
    checkScreenSize();

    // remove the event listener when isOpen is false
    return () => {
        window.removeEventListener('resize', checkScreenSize);
    };
  }, [isOpen]);

  return (
 
    

    <>
      {/* // desktop */}
      <nav className=' hidden lg:flex bg-transparent text-white w-full sticky top-0 flex-row px-16 py-2 justify-between z-[45]'>

        <div>
          <h1 className='Font-extrabold text-red text-2xl' >
            BAM
          </h1>
        </div>

        <ul className=' flex flex-row gap-4 '>
          <li><a href="#about"> About </a></li>
          <li><a href="#projects"> Projects </a></li>
          <li><a href="#contact"> Contact </a></li>
        </ul>

      </nav>




      {/* mobile and smaller screens */}
      <nav className=' w-full h-12 bg-transparent fixed top-0  lg:hidden flex z-[45]'>

        <div className='flex flex-row w-full h-full items-center justify-end px-16 py-2 z-50'>
          <button onClick={toggleMenu} className="flex flex-col gap-1 ">
            <div className={`rounded-full h-[3px] w-[23px] bg-white transition-transform duration-500 ${isOpen ? 'rotate-45 translate-y-[7px] ' : ''}`} />
            <div className={`rounded-full h-[3px] w-[15px] bg-white transition-opacity duration-500 ${isOpen ? ' opacity-0' : 'opacity-100'}`} />
            <div className={`rounded-full h-[3px] w-[23px] bg-white transition-transform duration-500 ${isOpen ? '-rotate-45 -translate-y-[7px]' : ''}`} />
          </button>

        </div>

        <div className={`fixed h-full w-full bg-[#252525] text-white flex justify-center items-center top-0 transition-transform duration-500 delay-200 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>

          <ul className='flex flex-col gap-4 '>
            <li className={` transition-transform duration-500 delay-100 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
              <a href="#about" onClick={toggleMenu} >About</a></li>
            <li className={` transition-transform duration-500 delay-200 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
              <a href="#projects" onClick={toggleMenu} >Projects </a></li>
            <li className={` transition-transform duration-500 delay-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
              <a href="#contact" onClick={toggleMenu} >Contact</a></li>
          </ul>
        </div>

      </nav>

    </>


  )
}
