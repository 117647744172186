import React, { useEffect, useState } from 'react'
import Arrow from '../assets/arrow.svg';
import circle from '../assets/circle.svg';
import arrowDown from '../assets/arrowDown.svg';


export const Hero = () => {

  let lastX = 0;
  let lastY = 0;

  const [updateCirclesPosition, setupdateCirclesPosition] = useState(false)
  const [circleCentered, setcircleCentered] = useState(true)
  let scheduled = false;

  const mouseEnterHero = () => {
    document.documentElement.style.setProperty("--mask-size", '32px');
  }

  const mouseLeaveHero = () => {
    document.documentElement.style.setProperty("--mask-size", '0px');
  }

  function mouseEnterText() {
    document.documentElement.style.setProperty("--mask-size", '350px');
  }
  function mouseLeaveText() {
    document.documentElement.style.setProperty("--mask-size", '32px');
  }


  const mouseEnterCircles = () => { setupdateCirclesPosition(true); setcircleCentered(false) }
  const mouseLeaveCircles = () => { setupdateCirclesPosition(false) }




  function mouseMoving(event) {

    lastX = event.clientX;
    lastY = event.clientY;

    if (!scheduled) {

      if (updateCirclesPosition) {


        requestAnimationFrame(() => {
          CircleUpdatePosition()
          document.documentElement.style.setProperty('--mask-x', lastX + 'px');
          document.documentElement.style.setProperty('--mask-y', lastY + window.scrollY + 'px');
          scheduled = false;
        });
        scheduled = true;
      }
      else if (!circleCentered) {
        const circles = document.querySelectorAll('.circle');
        requestAnimationFrame(() => {
          circles.forEach(circle => { circle.style.transform = `translate(0px, 0px)`; })
          scheduled = false;
        });
        scheduled = true;
        setcircleCentered(true);
      } else {
        requestAnimationFrame(() => {
          document.documentElement.style.setProperty('--mask-x', lastX + 'px');
          document.documentElement.style.setProperty('--mask-y', lastY + window.scrollY + 'px');
          scheduled = false;
        });
      }
      scheduled = true;
    }
  }

  function CircleUpdatePosition() {

    const circles = document.querySelectorAll('.circle');
    const container = document.getElementById('circle_container');
    const containerRect = container.getBoundingClientRect();


    circles.forEach(circle => {
      let circleWidth = circle.offsetWidth;
      let circleHeight = circle.offsetHeight;

      let relativeX = lastX - containerRect.left - containerRect.width / 2;
      let relativeY = lastY - containerRect.top - containerRect.height / 2;

      relativeX = Math.max(-(containerRect.width / 2 - circleWidth / 2), Math.min(containerRect.width / 2 - circleWidth / 2, relativeX));
      relativeY = Math.max(-(containerRect.height / 2 - circleHeight / 2), Math.min(containerRect.height / 2 - circleHeight / 2, relativeY));

      circle.style.transform = `translate(${relativeX}px, ${relativeY}px)`;
    });


  }


  useEffect(() => {

    document.addEventListener("mousemove", mouseMoving);

    return () => {
      document.removeEventListener("mousemove", mouseMoving);
    }
  },)



  return (
    <section className='relative h-screen w-full overflow-hidden z-30' onMouseEnter={mouseEnterHero} onMouseLeave={mouseLeaveHero}>
      <div className='layer bg'>
        <h1 className='Font-extrabold text-red text-2xl text-white absolute top-3 left-3' >
          BAM
        </h1>
        <span className='flex flex-row gap-2 text-white'>
          hover
          <img src={Arrow} alt="right arrow" />
        </span>

        <div className='text_container text-white' >
          <div>Hey There</div>
          <div>It's</div>
          <div>Brayan</div>
        </div>
      </div>

      <div id='hidden_Layer' className={`layer bg `}>
        <h1 className='Font-extrabold text-red text-2xl text-white absolute top-3 left-3' >
          BAM
        </h1>
        <div className='text_container text-white' onMouseEnter={mouseEnterText} onMouseLeave={mouseLeaveText}>
          <div>Get</div>
          <div>To</div>
          <div>Know Me</div>
        </div>
      </div>


      <div className='w-full h-40 flex items-center justify-center absolute bottom-0 '>
        <a href='#about' id='circle_container' className=' h-40 w-40 relative flex items-center justify-center ' onMouseEnter={mouseEnterCircles} onMouseLeave={mouseLeaveCircles}>

          <div id='circle' className=' h-16 w-16 absolute circle transition-all '>
            <img src={circle} alt="circle" className='object-contain absolute' />
            <img src={arrowDown} alt="arrow" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
          </div>


          <div className='h-28 w-28 p-2  absolute transition-all circle '>
            <img src={circle} alt="circle" className='object-contain' />
          </div>

        </a>
      </div>

    </section>


  )
}