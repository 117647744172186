import React from 'react'
import carRental1 from '../assets/carRental1.png'
import carRental2 from '../assets/carRental2.png'
import carRental3 from '../assets/carRental3.png'
import ecuamoringa1 from '../assets/ecuamoringa1.png'
import ecuamoringa2 from '../assets/ecuamoringa2.png'
import ecuamoringa3 from '../assets/ecuamoringa3.png'
import linkArrow from '../assets/linkArrow.svg';


export const Projects = () => {
    return (
        <section id='projects' className=' bg-primary-color py-16 text-white'>

            <div className='w-full bg-primary-color flex justify-center px-4 py-2 sticky top-0 z-10'>

                <div className=' w-fit relative'>
                    <h1 className='font-extrabold text-4xl inline-flex w-auto relative'>Projects</h1>
                </div>

            </div>


            <div className='min-h-screen w-full '>
                <div className='w-full car-rental-bg min-h-screen sticky top-0'></div>


                <div className=' sticky lg:top-[10%] lg:w-1/3 w-full p-5'>
                    <div className='bg-[#10101090] w-full space-y-2 p-5 flex flex-col rounded-md'>

                        <div className='px-10 py-4 bg-[#1a1a1a] flex flex-col space-y-2 rounded-lg'>
                            <h2 className=' font-bold text-center text-lg '>Rent Car Demo</h2>
                            <p>
                                Introducing our demo application: "Rent Car Demo".
                                Seamless platform for users to browse, select, and rent their ideal car with ease. With user-friendly navigation and intuitive features,
                                finding and renting the perfect vehicle has never been simpler.
                            </p>
                        </div>


                        <span className=' content-none w-[80%] h-[2px] bg-gray-50' />
                        <span className=' content-none w-[40%] h-[2px] bg-gray-50' />


                        <div className='px-10 py-4 bg-[#1a1a1a] flex flex-col space-y-3 rounded-lg'>
                            <h2 className=' font-bold text-center text-lg '>Tech</h2>
                            <ul className=' grid grid-flow-row grid-cols-2 gap-2'>
                                <li className=''>React</li>
                                <li className=''>Next.js</li>
                                <li className=''>TypeScript</li>
                                <li className=''>Tailwind</li>
                                <li className=''>Rest Api</li>
                                <li className=''>Html</li>
                                <li className=''>Css</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='lg:items-end w-full sticky top-full flex flex-col gap-8 px-10 py-10 '>
                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={carRental1} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={carRental2} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={carRental3} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2 bg-orange-600 rounded-lg'>
                    <a href="https://my-car-rental-demo.vercel.app" rel="noopener noreferrer" target='_blank' className='font-bold text-lg flex flex-row items-center justify-center'>
                        Click Here to Visit Website <img src={linkArrow} alt="Link" className=' object-contain px-2' />
                    </a>
                    </div>
                </div>


            </div>

            {/* moringa image */}
            <div className='min-h-screen w-full '>
                <div className='w-full moringa-bg min-h-screen sticky top-0'></div>


                <div className=' sticky lg:top-[5%] lg:w-1/3 w-full p-5'>
                    <div className='bg-[#00520090] w-full space-y-2 p-5 flex flex-col rounded-md'>

                        <div className='px-10 py-4 bg-[#172f14] flex flex-col space-y-2 rounded-lg'>
                            <h2 className=' font-bold text-center text-lg '>Ecuamoringa</h2>
                            <p>
                                Introducing Ecuamoringa Moringa: Dive into the world of Moringa and discover its extraordinary benefits
                                and versatile uses. Whether you're interested in its health advantages, culinary uses, or environmental
                                impact, we offer the best that Moringa has to offer. Explore our range of products and detailed information,
                                designed to provide you with a holistic understanding of this remarkable plant. With Ecuamoringa,
                                accessing the full potential of Moringa has never been easier.
                            </p>
                        </div>


                        <span className=' content-none self-end w-[80%] h-[2px] bg-gray-50' />
                        <span className=' content-none self-end w-[40%] h-[2px] bg-gray-50' />


                        <div className='px-10 py-4 bg-[#172f14] flex flex-col space-y-3 rounded-lg'>
                            <h2 className=' font-bold text-center text-lg '>Tech</h2>
                            <ul className=' grid grid-flow-col gap-2'>
                                <li className=''>Css</li>
                                <li className=''>Html</li>
                                <li className=''>JavaScript</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='lg:items-end w-full sticky top-full flex flex-col gap-8 px-10 py-10 '>


                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={ecuamoringa1} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={ecuamoringa2} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2'><img className=' shadow-lg shadow-black rounded-lg object-contain ' src={ecuamoringa3} alt="car rental home Page" /></div>
                    <div className='lg:w-1/2 bg-green-700 rounded-lg'>
                    <a href="https://moringa-demo.vercel.app" rel="noopener noreferrer" target='_blank' className='font-bold text-lg flex flex-row items-center justify-center'>
                        Click Here to Visit Website <img src={linkArrow} alt="Link" className=' object-contain px-2' />
                    </a>
                    </div>
                </div>


            </div>





        </section >
    )
}


