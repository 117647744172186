import React from 'react'
import { TechStack } from './TechStack'
import image from '../assets/helloComputer.jpg'

export const AboutMe = () => {
    return (


        <section id='about' className='px-24 py-16 bg-primary-color text-white text-center flex flex-col justify-between items-center min-h-screen'>

            <div className='w-full bg-primary-color flex justify-center px-4 py-2 sticky top-0 z-10'>
                <div className=' w-fit relative'>
                    <h1 className='font-extrabold text-4xl inline-flex w-auto relative'>About Me</h1>
                </div>
            </div>


            <div className='flex w-full lg:flex-row flex-col-reverse py-8 lg:space-x-32 items-center'>
                <div className=' flex flex-col gap-4 items-center w-full'>
                    <h2 className='font-extrabold text-2xl md:text-4xl '>Hey It's Brayan</h2>
                    <p className='  text-lg py-5 px-2'>
                        I'm a cybersecurity graduate with a passion for staying at the forefront of technology.
                        My background in computer science has equipped me with a solid foundation, while my experience has
                        instilled in me a strategic mindset and attention to detail. I'm particularly enthusiastic about web development and
                        constantly seek to expand my knowledge in this field. With a proactive approach to learning and a commitment to excellence,
                        I'm driven to make a meaningful impact in the tech industry by leveraging my expertise and embracing new challenges with enthusiasm.
                    </p>

                    <span className='content-none place-self-center h-[2px] w-[80%] bg-[#666668]' />

                    <div className=' '>
                        <h3 className='font-extrabold text-xl md:text-2xl py-5'>Key Technical Proficiencies:</h3>
                        <TechStack />
                    </div>

                    <h1 className='font-extrabold text-xl md:text-1xl  py-12'>
                        I like to believe that every website tells a story... check out some of my <a href="#projects" className=''>projects</a> next :3</h1>

                </div>

                <div className=' lg:min-w-[300px] lg:max-w-none max-w-[445px]'>
                    <img src={image} className=' object-contain' alt="Computer say's hello" />
                </div>

            </div>
        </section>
    )
}
