
export const technologies = [
    'HTML', 'CSS', 'Tailwind', 'JavaScript', 'TypeScript',
    'React', 'Next.JS', 'Php', 'Java', 'C++', 'SQI'
];





