import React from 'react'

export const Contact = () => {
    return (
        <section id='contact' className='bg-primary-color py-16 text-white min-h-screen'>

            <div className='w-full bg-primary-color flex justify-center px-4 py-2 sticky top-0 z-10'>

                <div className=' w-fit relative'>
                    <h1 className='font-extrabold text-4xl  inline-flex w-auto  relative'>Contact</h1>
                </div>

            </div>

            <div className='w-full h-full px-20'>

                <h1 className='font-extrabold lg:text-8xl text-7xl my-16 '>Let's Work Together</h1>

                <div>
                    <h2 className=' lg:text-5xl text-4xl my-5'>Socials:</h2>
                    <ul className=' w-fit lg:text-3xl md:text-2xl text-lg space-y-5 overflow-hidden'>
                        <li className=''>
                                 <a href="mailto:brayanrivas0226@gmail.com" className='SocialLink inline-flex flex-col'>Gmail: Brayanrivas0226@gmail.com</a>
                        </li>
                        <div className=' content-none w-full h-[1px] bg-slate-700 ' />
                        <li><a href="https://github.com/Brayanketchup" rel="noopener noreferrer" target="_blank" className='SocialLink inline-flex flex-col'>GitHub: Brayanketchup</a></li>
                        <div className=' content-none w-full h-[1px] bg-slate-700 ' />
                        <li><a href="https://www.linkedin.com/in/brayan0226" rel="noopener noreferrer" target="_blank" className='SocialLink inline-flex flex-col'>Link In: Brayan Martinez</a></li>
                    </ul>
                </div>

            </div>


        </section>
    )
}
