
import './App.css';
import { Navbar, Projects, Hero, AboutMe, Contact, Footer } from './components';


function App() {

  return (
    <main>
      <Hero/>
      <Navbar/>
      <AboutMe/>
      <Projects/>
      <Contact/>
      <Footer/>
    </main>



  );
}

export default App;
