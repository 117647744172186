import React from 'react'
import { technologies } from '../constants';

console.log(technologies,  ' texts');

export const TechStack = () => {

 
    return (
        <>
            <div className=' w-full overflow-x-clip pb-3'>
                <div className='w-full relative'>
                    <ul className=' absolute flex flex-row gap-4 p-2 skillStack'>
                        {technologies.map((tech, index) => (
                            <li key={index} className='border-0 rounded-md sameSiseTest'>{tech}</li>
                        ))}
                        {technologies.map((tech, index) => (
                            <li key={`second-${index}`} className='border-0 rounded-md sameSiseTest'>{tech}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </>

    )
}



